.center-text.two-rows .secondary {
  font-size: .3em;
  margin-top: -1em;
}

.total-flags-center-text span:not(:last-child)::after{
  display: inline-block;
  content: '/';
  color: #144361;
}

.center-text .multiplier {
  font-size: 65%;
  font-weight: bold;
}

.supplier-page {
  dl dt{
    color: #888888;
    font-size: 16px;
  }
  .flag-analysis {
    clear: both;
    h2 {
      padding-top: 50px;
    }
  }

  section.supplier-general-statistics {
    h2 {
      margin-bottom: 40px;
      @media screen and (max-width: 1500px) {
        margin-bottom: 20px;
      }

      @media screen and (max-width: 1400px) {
        margin-bottom: 10px;
      }

      @media screen and (max-width: 1300px) {
        margin-bottom: 0;
      }

    }
  }
}

.recharts-tooltip-wrapper {
  visibility: visible!important;
  transform: none!important;
}

.recharts-text.recharts-label {
  tspan {
    display: none;
  }

  tspan:first-child {
    display: block;
  }
}
