.pe-page {
  font-family: 'Montserrat';
  section.info .info-table {
    font-family: 'Source Sans Pro';
    color: #354052;
    .flags {
      small {
        margin-left: 0;
      }
    }
  }
  .flag-analysis {
    h2 {
      margin-top: 40px;
    }
  }

  h2 {
    font-family: 'Source Sans Pro';
    font-weight: 600;
  }
}
