.dashboard-corruption-risk .clickable-crosstab {
  div.crosstab-box {
    background: #f0f0f0;
    font-size: .9em;
    .title {
      color: #144361;
      font-size: 1.3em;
    }
    h5 {
      color: green;
      font-weight: bold;
      margin-bottom: 5px;
    }
    p {
      color: #999999;
      margin-bottom: 0;
      strong {
        color: #333;
      }
    }
  }
}