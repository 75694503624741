@import url('https://fonts.googleapis.com/css?family=Montserrat:400,600,700|Source+Sans+Pro:400,600');

$headerHeight: 60px;
$filterHeight: 57px;
$gutterRight: 80pt;
$gutterRightMd: 60pt;
$base-space-sm: 10px;
$extend: 100%;
$theme-blue: #223a49;
$leftPadding: 15px;
$themeGreen: #29722d;
$resetColor: #dca402;
$borderColor: #eeeeee;

.dashboard-corruption-risk {

    .form-control {
        border: 2px solid $borderColor;
        box-shadow: none;
    }

    & > header {
        font-family: 'Montserrat', sans-serif;
        background: $theme-blue;

        &.branding {
            height: $headerHeight;
            overflow: visible;

            @media screen and (max-width: 414px) {
                height: 90px;
            }
        }

        a.logo-wrapper {
            text-decoration: none;
            font-size: 18px;
            line-height: 20px;
            font-weight: lighter !important;

            :visited {
                text-decoration: none;
            }

            :hover {
                text-decoration: none;
            }

            :active {
                text-decoration: none;
            }

            img {
                width: 55px;
                margin-left: 20px;
            }

            span {
                padding-left: 10px;
                color: #fff;
                line-height: 20px;
                font-weight: lighter !important;
            }
        }

        .react-bootstrap-table-pagination {
            margin-bottom: $base-space-sm;
        }

        .header-right {
            background-color: $themeGreen;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            @media (max-width: 768px) {
                margin-top: 0;
            }
            @media screen and (max-width: 414px) {
                margin-top: 5px;
            }

            .login-wrapper {
                text-align: center;
                width: $extend;

                button {
                    background: transparent;
                    border-color: transparent;
                    color: #ffffff;
                    font-weight: 600;
                    outline: none;
                    text-transform: uppercase;
                }
            }
        }
    }

    & > .filters-bar {
        background-color: #204e6e;
        font-family: 'Montserrat', sans-serif;
        border-bottom: 2px solid #f6f6f6;
        top: $filterHeight;
        left: 0;
        right: 0;
        position: fixed;
        z-index: 10;

        @media screen and (max-width: 992px) {
            padding-left: 15px;
            position: relative;
            top: 0;
        }

        .crd-filter-title {
            float: left;
            @media (max-width: 1024px) {
                display: none;
            }

            .title {
                color: #ffffff;
                float: right;
                margin-right: -50px;
                font-family: 'Source Sans Pro', sans-serif;
                font-size: 15px;
                font-weight: bold;
                padding: 17px 20px;
                @media (max-width: 1024px) {
                    margin-right: 5px;
                }
                @media (max-width: 969px) {
                    padding: 0 5px;
                }
            }
        }

        .filter {
            $boxFilterColor: #0083e5;
            $boxWidth: 500px;
            float: right;
            height: $extend;
            padding: 10px 0;
            margin-right: 16px;
            margin-top: $base-space-sm / 2;
            cursor: pointer;
            border-top: 3px solid transparent;
            border-bottom: 3px solid transparent;
            @media (max-width: 768px) {
                padding-left: 15px;
                width: $extend;
            }

            &.active {
                border-bottom: 3px solid $boxFilterColor;
            }

            .glyphicon-menu-down {
                font-size: .55em;
                margin-left: 4px;
                margin-top: 3px;
            }

            .field {
                header {
                    color: #333333;
                    font-weight: bold;
                    margin-bottom: 5px;
                }
            }

            .box-title {
                color: #ffffff;
                font-size: 13px;
                text-transform: uppercase;
            }

            &.open {
                position: relative;

                .box-title {
                    font-weight: 600;
                }

                .dropdown {
                    display: block;
                    position: absolute;
                    margin-top: 17px;
                    width: $boxWidth;
                    min-height: 400px;
                    border: 2px $borderColor solid;
                    background-color: white;

                    &.dropdown-r {
                        right: 0;
                    }

                    @media (max-width: 768px) {
                        left: 0;
                        margin-left: 0 !important;
                        width: $extend;
                    }

                    .box-content {
                        overflow-y: auto;
                        padding: 25px 25px 80px 25px;
                        height: $extend;

                        .input-sm {
                            height: 40px;
                        }

                        .hint {
                            margin-top: 20px;
                        }
                    }

                    .controls {
                        background: white;
                        position: absolute;
                        bottom: 0;
                        left: 25px;
                        right: 25px;
                        padding-bottom: 25px;
                        padding-top: 25px;

                        .btn.btn-reset {
                            background-color: $resetColor;
                            border-color: $resetColor;
                            color: #ffffff;
                            outline: none;
                            padding: 6px 25px;

                            &:hover {
                                background-color: $theme-blue;
                            }
                        }

                        .btn.btn-apply {
                            background-color: $themeGreen;
                            border-color: $themeGreen;
                            color: #ffffff;
                            outline: none;
                            padding: 6px 25px;

                            &:hover {
                                background-color: $theme-blue;
                            }
                        }
                    }
                }
            }

            .glyphicon-menu-down {
                color: #ffffff;
                font-size: .75em;
                margin-left: 20px;
            }

            .dropdown {
                display: none;
            }
        }

        .download {
            padding: 14px 15px;
            text-align: right;

            button {
                padding: 5px 25px;
                margin-right: $gutterRight;
                @media (max-width: 1200px) {
                    margin-right: $gutterRightMd;
                }
            }
        ;
        }

        .box-date {
            .toggleable-wrapper {
                width: 40*4px;
            }

            .toggleable-item {
                display: inline-block;
                background: #add3ee;
                color: white;
                padding: 5px 10px;
                margin: 1px;
                font-weight: bold;
                text-transform: uppercase;
                user-select: none;

                &.selected {
                    background: #4278b0;
                }
            }
        }
    }

    aside {
        bottom: 0;
        background: transparent;
        border-right: none;
        height: $extend;
        margin-left: -15px;
        overflow-y: scroll;
        padding-bottom: 200px;
        padding-right: 0;
        top: $filterHeight * 2;

        @media (max-width: 768px) {
            margin-left: 0;
            padding-bottom: 30px;
            position: relative !important;
            top: auto;
            width: 100%;
        }

        >div {
            height: 500px;
        }

        .small {
            font-size: 80%;
            line-height: 128%;
        }

        i {
            color: #34ac45;
            float: right;
        }

        .total-flags {
            background: #fafafa;
            margin: 15px 0 0 -15px;
            padding-bottom: 50px;
            $infoWidth: 250px;
            border-radius: 5px;
            border: 1px solid #e7ebef;
            border-left-style: none;
            padding-bottom: 200px;
            .message{
                position: absolute;
                top: 45px;
            }
            .counter {
                padding: 10px 20px 15px 20px;

                &:first-child {
                    border-bottom: 1px solid #e7ebef;
                }

                .text {
                    font-size: 0.95vw;
                    padding-top: .5em;
                    @media (max-width: 768px) {
                        font-size: 12px;
                    }
                }

                .count {
                    float: right;
                    font-size: 1.5vw;
                    font-weight: bold;
                    color: $theme-blue;
                    @media (max-width: 768px) {
                        font-size: 12px;
                    }
                }
            }

            .crd-legend {
                width: $infoWidth;
                $boxSize: 11px;
                margin: 20px auto;

                div {
                    width: $infoWidth / 4;
                    float: left;
                    font-size: $boxSize;
                    text-align: center;

                    &:before {
                        display: inline-block;
                        content: "";
                        width: $boxSize;
                        height: $boxSize;
                        margin-top: 4px;
                        margin-right: $boxSize / 2;
                        transform: translateY(1px);
                        border: 2px solid red;
                        border-radius: $boxSize;
                    }
                }

                .rigging {
                    width: $infoWidth / 2;
                    &:before {
                        border-color: #3372b1;
                        margin-left: auto;
                        margin-right: auto;
                        width: 11px;
                    }
                    .frc-label{
                        display: block;
                    }
                }
                .fraud{
                    &:before {
                        border-color: #289df5;
                        margin-left: auto;
                        margin-right: auto;
                        width: 11px;
                    }
                    .frc-label{
                        display: block;
                    }
                }
                .collusion{
                    &:before {
                        border-color: #fac329;
                        margin-left: auto;
                        margin-right: auto;
                        width: 11px;
                    }
                    .frc-label{
                        display: block;
                    }
                }
            }
        }

        [role=navigation] {
            font-family: 'Source Sans Pro', sans-serif;
            margin-top: -1px;
            border-right: 1px solid #e7ebef;
            border-bottom-right-radius: 5px;
            margin-right: 0;
            margin-left: -15px;

            a {
                font-weight: 700;
                border-bottom: 1px solid #e6eaee;
                color: #000000;
                background: #f9f9f9;
                cursor: pointer;
                display: block;
                font-size: 15px;
                line-height: 40px;
                padding: 5px 0 5px 25px;
                text-decoration: none;
                white-space: nowrap;
                @media (max-width: 1200px) {
                    font-size: 13px;
                }

                &:first-child {
                    border-top: 1px solid #e6eaee;
                }

                .count {
                    color: #000000;
                    display: inline-block;
                    margin-left: 10px;
                }

                &.archive-link {
                    background: #f9f9f9;
                }

                &.contracts-link {
                    background: #f9f9f9;
                }

                img {
                    display: inline-block;
                    margin-right: 20px;
                    width: 35px;

                    &.white {
                        display: none;
                    }
                }

                &.active, &:hover {
                    background: #e5eaeb;
                    color: #3090bd;

                    .count {
                        color: #3090bd;
                    }

                    img.white {
                        display: inline-block;
                    }

                    img.blue {
                        display: none;
                    }
                }
            }

            .crd-description {
                font-size: 15px;
                padding: 15px 25px 8px 25px;
                display: none;
                @media (max-width: 1200px) {
                    font-size: 12px;
                }

            }

            .crd-description-link {
                &.active {
                    & + .crd-description {
                        display: block;
                    }
                }

                &:hover, &.active {
                    .glyphicon {
                        color: white;
                    }
                }

                .glyphicon {
                    color: #34ac45;
                    padding: 10px 30px;
                    font-size: 1.5em;
                }
            }
        }
    }

    .table-striped > tbody > tr:nth-of-type(odd) {
        background-color: #ffffff;
    }

    .table-striped > tbody > tr:nth-of-type(even) {
        background: #f5f8fa;
    }

    .content {
        $arrowSize: 8px;
        margin-top: $filterHeight * 2;

        @media (max-width: 1200px) {
          padding: 30px $gutterRightMd 0 50px;
        }

        @media screen and (max-width: 992px) {
            margin-top: 0;
        }

        @media (max-width: 768px) {
          padding: 40px;
          margin-top: 0;
          padding: 40px;
        }


        h2, h3, h4 {
            font-family: 'Source Sans Pro', sans-serif;
            font-weight: 600;
        }

        .page-header {
            border-bottom: none;
        }
        .table-section{
            table{
                margin-top: 20px;
            }
        }
        .page-corruption-type, .page-individual-indicator {
            h2 {
                margin-top: 15px;
            }

            p.introduction, p.definition {
                font-family: Montserrat, sans-serif;
                font-size: 12px;
            }

            p.introduction, h2 + p.definition {
                margin-top: -20px;
            }

            .table-crosstab {
                .name-column {
                    min-width: 130px;
                }
            }

            .crosstab-container {
                overflow-x: auto;
            }
        }

        .page-corruption-type p.introduction {
            margin-bottom: 20px;
        }

        .page-individual-indicator p.definition {
            margin-bottom: 0;
        }

        .crd-popup {
            $popupWidth: 400px; //if you're changing this you need to also change POPUP_WIDTH in constants.js
            position: absolute;
            top: 0;
            left: 0;
            width: $popupWidth;
            height: 150px;

            hr {
                width: 50%;
            }
        }

        .crosstab-box, .crd-popup {
            $popupColor: #222c3c;
            z-index: 1;
            background: $popupColor;
            padding: 15px 5px;
            border-radius: 5px;
            pointer-events: none;

            .title {
                color: #70819b;
                font-weight: bold;
                padding-right: 0;
            }

            .info {
                color: white;
            }

            hr {
                opacity: .1;
                margin: 5px auto;
            }

            .arrow {
                background: $popupColor;
                position: absolute;
                left: 50%;
                bottom: -$arrowSize/2;
                width: $arrowSize;
                height: $arrowSize;
                margin-left: -$arrowSize/2;
                transform: rotate(45deg);
            }
        }

        .crosstab-box {
            padding: 15px;

            hr {
                width: $extend;
            }
        }


        .chart-corruption-types {
            position: relative;

            .hovertext {
                opacity: 0;
            }

            h3 {
                margin-top: 15px;
            }
        }

        .indicator-tile-container {
            padding: 10px;

            .border {
                border: 1px solid #e6eaee;
                border-radius: 5px;
                padding: 20px;
                cursor: pointer;
                min-height: 500px;

                &:hover {
                    border: 1px solid #1f77b4;

                    h4 {
                        color: #1f77b4;
                    }
                }

                h4, p {
                    font-weight: bold;
                }

                p {
                    color: #156d03;
                    height: 5.3em;
                    margin-bottom: 10px;
                }
            }
        }

        .table-crosstab {
            font-family: 'Source Sans Pro', sans-serif;
            font-size: 12px;
            font-weight: 600;

            .nr-flags {
                color: #004f99;
            }

            thead thead tr th {
                border-bottom-width: 1px;
            }

            td, th {
                text-align: center;
                vertical-align: middle;
            }

            th{
                &:nth-of-type(5),
                &:nth-of-type(6),
                &:nth-of-type(7){
                    width: 20%;
                }
            }
            td:first-child {
                text-align: right;
            }

            .not-applicable {
                color: #b6cddc;
                background: #f1faff;
            }

            .crd-popup {
                width: 300px;
            }
        }

        td.hoverable:hover, td.selectable.selected {
            box-shadow: inset 0px 0px 0px 5px #ffd307;
        }

        .react-bs-table-container {
            font-family: 'Source Sans Pro', sans-serif;
            font-weight: 600;
            font-size: 12px;

            th {
                color: #a1adbc;
            }

            @media (max-width: 768px) {
                font-size: 8px;
                margin-left: -30px;
                overflow-x: auto;
                width: 115%;
            }

            .react-bs-table-bordered{
              @media (max-width: 768px){
                overflow-x: scroll;
                width: 200%;
              }
            }

            .react-bootstrap-table {
                overflow-x: auto;

                .table {
                    @media (max-width: 768px) {
                        min-width: 550px;
                    }
                }
            }
        }

        .react-bs-table-container {
            &, td.hoverable {
                overflow: visible;
            }

            td.hoverable {
                padding: 0;

                & > div {
                    padding: 8px;
                }
            }
        }

        td.hoverable {
            position: relative;

            .crd-popup {
                padding: 15px;
                height: auto;
                display: none;
                z-index: 10;

                .and {
                    text-transform: uppercase;
                    color: #387ab2;
                }

                hr {
                    width: auto;
                }

                h5 {
                    font-weight: bold;
                }
            }

            &:hover {
                .crd-popup {
                    display: block;
                    top: auto;
                    bottom: $extend;
                    left: 50%;
                    margin-left: -150px;
                }
            }

            &.popup-left:hover .crd-popup {
                left: auto;
                right: $extend;
                top: 50%;
                bottom: auto;
                margin-left: 0;
                margin-right: $arrowSize;
                transform: translateY(-50%);
            }
        }

        .popup-left .crd-popup .arrow {
            left: $extend;
            bottom: 50%;
        }
    }

    .chart-container .message {
        background: #234e6d;
        border: 1px solid #d9effd;
        position: absolute;
    }

    .oce-3-line-text {
        overflow: hidden;
        max-height: 4em;
        display: block;

        &:hover {
            overflow: visible;
            max-height: none;
        }
    }

    .crd-fullscreen-popup {
        $popupWidth: 75%;
        position: fixed;
        background: #f9fff6;
        z-index: 9999;
        width: $popupWidth;
        height: auto;
        max-height: 99%;
        left: ($extend - $popupWidth) / 2;
        padding: 30px;
        overflow-x: hidden;
        overflow-y: auto;
        font-size: .9em;

        .popup-title, .section-title h5 {
            color: #27587e;
            font-weight: bold;
        }

        hr {
            margin-top: 5px;
            margin-bottom: 0;
        }

        .close-button {
            color: #c9c9c9;
            cursor: pointer;
            font-size: 21px;
            display: block;
            margin-top: -15px;
        }

        .section-title {
            * {
                font-size: 15px;
            }
        }

        .text-column-left {
            text-align: justify;
        }

        .text-column-right {
            text-align: justify;
        }

        .intended-use-item {
            padding-top: 15px;
        }

        .contact {
            padding-top: 10px;
        }

        .btn-primary {
            background-color: #33a9f7;
            border: none;
            text-transform: uppercase;
            padding: 14px 24px;
            font-size: 11px;
        }
    }

    .crd-fullscreen-popup-overlay {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 9998;
        background: rgba(0, 0, 0, .7);
    }
}

.custom-popup-container {
    position: relative;

    .crd-popup {
        display: none;
    }

    &:hover .crd-popup {
        display: block;
    }
}

.contract-page, .supplier-page, .pe-page {
    dl {
        margin-bottom: 10px;

        dt {
            color: #888888;
            font-size: 16px;
            font-weight: normal;
        }

        dd {
            font-size: 1.3em;

            p {
                margin-bottom: 0;
            }
        }

        &.smaller {
            dd {
                font-size: 1em;
            }
        }
    }

    table {
        &.join-bottom {
            margin-bottom: 0;

            &, td {
                border-bottom: none;
            }
        }

        &.info-table {
            th, td {
                width: 33%;
            }
        }
    }

    section h2 {
        color: #ce4747;
    }

    .flag-icon {
        width: 32px;
        margin-top: -5px;
    }

    section.info {
        margin-bottom: 40px;

        & > .row {
            margin-left: 0;
            margin-right: 0;

            & > * {
                padding-left: 0;
            }
        }

        .flags {
            font-size: 2em;

            .count {
                color: #ce4747;
                font-weight: 600;
            }

            small {
                margin-left: 40px;
                font-size: .6em;
                display: block;
            }
        }
    }
}

.oce-chart {
    position: relative;
    $msgWidth: 200px;
    $msgHeight: 70px;

    .message {
        position: relative;
        top: 50%;
        left: 50%;
        margin-bottom: 20px;
        margin-left: -$msgWidth / 2;
        width: $msgWidth;
        height: $msgHeight;
        background-color: #234e6d;
        border: 1px solid #cccccc;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .recharts-label, .recharts-cartesian-axis-ticks {
        fill: #adafb2;
        font-size: 12px;
        font-family: Montserrat;
        font-weight: 600;
    }

    .recharts-legend-item-text {
        font-family: Source Sans Pro;
        font-size: 16px;
        font-weight: 600;
        color: #767676;
    }

    & + .title {
        font-family: 'Source Sans Pro';
        font-size: 18px;
        font-weight: 600;
        text-align: left;
        color: #354052;
        text-indent: -5px;
        padding-right: 40px;

        button {
            float: right;
            margin-right: -40px;
        }
    }
}
