
.react-bootstrap-table .table {
  table-layout: fixed;
  margin-bottom: 15px;
}

.react-bootstrap-table table td {
  overflow: hidden;
}

.react-bootstrap-table-page-btns-ul {
  float: right;
  margin-top: 0;
}

.react-bs-table-sizePerPage-dropdown .btn-default {
  border-color: #ddd;
}

.react-bootstrap-table-pagination {
  margin-top: 15px;
}
