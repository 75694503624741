.top-search {
  z-index: 0;
  margin-top: 10px;
  margin-bottom: 5px;
  input.form-control {
    border-radius: 4px !important;
    font-size: 12px;
    font-weight: 600;
    font-family: Montserrat;
  }
  .input-group-addon {
    background: transparent;
    border: none;
    .glyphicon {
      margin-left: -60px;
      z-index: 3;
    }
  }
}
