.center-text-donut {
  text-align: center;
  &>div{
    position: relative;
  }

  &>div, .center-text {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .center-text {
    font-size: 3vw;
    font-weight: bold;
    color: #144361;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    pointer-events: none;
  }

  &.nr-of-bidders{
    .secondary {
      color: #c0c0c0;
    }
  }

  &.nr-contracts{
    .secondary {
      font-size: .3em;
      margin-top: -1em;
    }
  }
}
