.chart-container{
  position: relative;
  .message{
    position: relative;
    top: 30%;
    left: 50%;
    width: 100px;
    margin-left: -50px;
    padding: 25px;
    z-index: 1;
    background: #234e6d;
    color: white;
    border: 1px solid #cccccc;
    text-align: center;
    vertical-align: middle;
    font-weight: 100;
    font-size: 1.2em;
  }
}
