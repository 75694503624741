$headerHeight: 48px;
$windowHeight: 300px;
$borderRadius: 12px;
$primary-green: #29722d;

.content.map-content{
  height: $windowHeight * 2.5;
  margin-top: 0;
  margin-bottom: -95px;
  position: relative;
  @media screen and (max-width: 767px) {
    height: $windowHeight + 50 !important;
    margin-left: 2%;
    width: 96%;
  }


  .leaflet-control{
    .btn{
      background-color: #f9f9f9;
      border: 2px solid #f9f9f9;
      outline: none;
      &.btn-primary{
        background-color: $primary-green;
        border: 2px solid $primary-green;
        border-color: $primary-green;
      }
    }
  }

  .leaflet-bar{
    a{
      color: #000000;
    }
  }
  .leaflet-control-attribution{
    a{
      color: #000000;
    }
  }
}

.leaflet-popup-content{
  width: 580px!important;
  height: $windowHeight;
  @media screen and (max-width: 600px) {
    width: 300px !important;
  }
}

@keyframes fade-in {
  from { opacity: 0 }
  to { opacity: 1 }
}

.tender-locations-popup{
  font-size: 9pt;
  .map-chart{
    .chart-container{
      padding-top: 10px;
    }
    .main-svg{
      animation: fade-in 1s;
      background: transparent!important;
    }
  }
  header{
    background: $primary-green;
    margin-top: -14px;
    margin-left: -20px;
    margin-right: -20px;
    border-top-right-radius: $borderRadius;
    border-top-left-radius: $borderRadius;
    padding: 12px;
    color: white;
    font-size: 12pt;
    font-weight:600;
    height: $headerHeight;
  }

  a.leaflet-popup-close-button{
    color: #ffffff;
    top: .75em;
    right: 15px;
  }

  .tabs-bar{
    background: #272727;
    margin-left: -5px;
    height: $windowHeight - $headerHeight + 14px;//magic number, leaflet's borders n stuff
    border-bottom-left-radius: $borderRadius;
    div{
      padding: 10px 10px 10px 25px;
      margin-left: -15px;
      margin-right: -15px;
      border-right: 3px solid transparent;
      &:first-child{
        margin-top: 10px;
      }
      &:focus{
        outline: none;
      }
      &.active{
        background: #0f0f0f;
        border-right-color: $primary-green;
        a{
          color: #d4d4d4;
        }
      }
    }
    a{
      color: #848c98;
      text-decoration: none;
    }
  }

  .map-chart.overview{
    .legendsymbols{
      display: none;
    }

    .legendtext{
      text-transform: uppercase;
    }
  }
}
