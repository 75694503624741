.introjs-helperLayer {
    background-color: rgba(255,255,255,.5);
}

.introjs-helperNumberLayer {
    left: 10px;
}

.total-item {
    color: white;
    margin-top: 20px;
    text-transform: uppercase;
    @media screen and (max-width: 992px){
        margin-bottom: 10px;
        margin-top: 10px;
        overflow: hidden;
    }
    .total-label {
        float: left;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        line-height: 30px;
        padding-left: 15px;
        @media screen and (max-width: 992px) {
            font-size: 10px;
        }
    }

    .total-number {
        float: left;
        font-size: 19px;
        line-height: 160%;
        margin-left: 10px;
        padding-left: 10px;
        border-left: 1px solid white;
        font-family: 'Montserrat', sans-serif;
        @media screen and (max-width: 992px) {
            font-size: 18px;
        }
    }
}
