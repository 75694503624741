.contracts-page{
  margin-bottom: 40px;
  table {
    th, td{
      $width: 12.5%;
      $ocidDateDelta: 3%;
      width: $width;
      &.ocid {
        width: $width + $ocidDateDelta;
      }
      &.date {
        width: $width - $ocidDateDelta;
      }
    }
  }
}

.react-bootstrap-table table td, .react-bootstrap-table table th{
  white-space: normal;
}

.contract-page {
  .flag-analysis {
    clear: both;

    h2 {
      padding-top: 50px;
    }
  }

  section.contract-statistics {
    h2 {
      margin-bottom: 40px;
      @media screen and (max-width: 1500px) {
        margin-bottom: 20px;
      }

      @media screen and (max-width: 1400px) {
        margin-bottom: 10px;
      }

      @media screen and (max-width: 1300px) {
        margin-bottom: 20px;
      }

    }
  }
}

.contract-label{
  color: #888888;
  font-size: 16px;
}
.contract-value{
  color: #000000;
  display: block;
  font-size: 16px;
  font-weight: 700;
  line-height: 125%;
}
