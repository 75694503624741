.chart-tools{
  a{
    outline: none;
  }
  img{
    margin-right: 10px;
    outline: none;
    width: 16px;
  }
}
.tender-locations-popup{
  .chart-container svg.main-svg{
    overflow: visible;
  }
  .g-ytitle{
    transform: translateX(-10px);
  }
}
