$color-green: #29722d;

.alerts {
    color: #333;

    .title {
        padding-bottom: 10px;
        border-bottom: 1px solid $color-green;
    }

    .sub-title {
        font-weight: bold;
        padding-bottom: 10px;
    }

    .validation-message {
        padding-top: 30px;
    }

    .apply-button {
        border-top: 1px solid $color-green;
        margin-top: 50px;
        padding-top: 50px;

        .submit {
            background-color: $color-green;
            padding: 15px;
        }
    }

}
